import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout/Layout'
import NavBar from '../fragments/invited/NavBar/NavBar'
import Hero from '../fragments/invited/Hero/Hero'
import Footer from '../components/Footer/Footer'
import Phones from '../fragments/index/Phones/Phones'
import MakeMoney from '../fragments/index/MakeMoney/MakeMoney'
import Cards from '../fragments/index/Cards/Cards'
import Freedom from '../fragments/index/Freedom/Freedom'
import Investor from '../fragments/index/Investor/Investor'
import Social from '../fragments/index/Social/Social'
import { useBranchData } from '../utils/branch'

const Download = ({ location }) => { const branchData = useBranchData()
  console.log(branchData)
  return (
    <>
      <NavBar />
      <Hero location={location} branchData={branchData} />
      <Phones />
      <MakeMoney />
      <Cards />
      <Freedom />
      <Investor />
      <Social />
      <Footer />
    </>
  )
}

const LayoutContainer = props => {
  return (
    <>
      <Layout isWaitlist>
        <Download {...props} />
      </Layout>
    </>
  )
}

export default LayoutContainer

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
